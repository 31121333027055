import React, { Component, } from 'react';
import YAML from 'yaml';

import { clipDuration } from './playlist';

class YamlEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            className: '',
            yaml: this.props.timeline.state.yaml
        }
        this.state.displayYaml = this.getYaml(this.props.timeline.state.checked)
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSeek = this.handleSeek.bind(this);
        this.props.timeline._editor = this
    }

    getYaml(checked) {
        if (checked.length === 0) {
            return this.state.yaml
        } else if (this.state.yaml) {
            var yaml = YAML.parse(this.state.yaml)
            yaml = yaml.filter((track) => { return checked.indexOf(track.name) > -1; })
            return YAML.stringify(yaml)
        } else {
            return ''
        }
    }

    handleChange(event) {
        var current, yaml,
            state = {
                className: '',
                displayYaml: event.target.value
            }
        try {
            yaml = YAML.parse(state.displayYaml)
        } catch(e) {
            state.className = 'invalid'
        }
        if (!yaml || typeof(yaml) == 'string') {
            state.className = 'invalid'
        }
        if (!state.className.length) {
            if (this.props.timeline.state.checked.length) {
                current = {}
                yaml.forEach((track) => {
                    current[track.name] = track
                })
                state.yaml = YAML.stringify( this.state.yaml ? YAML.parse(this.state.yaml).map((track) => {
                    return track.name in current ? current[track.name] : track
                }) : yaml)
            } else {
                state.yaml = YAML.stringify(yaml)
            }
        }
        this.setState(state)
    }

    handleSeek(event) {
        var timeline = this.props.timeline
        var player = timeline.props.player
        var start = '  - '
        var pos0 = this.refs.text.value.lastIndexOf(start, this.refs.text.selectionStart)
        var pos1 = this.refs.text.value.indexOf(start, pos0 + 1)
        pos0 = this.refs.text.value.lastIndexOf('\n', pos0)
        var currentClip = this.refs.text.value.slice(pos0, pos1)
        var clip
        try {
            clip = YAML.parse(currentClip)[0]
        } catch {}
        if (clip) {
            var position
            if (clip.position !== undefined) {
                position = clip.position
            } else {
                var found = false
                timeline.state.tracks.some(track => {
                    track.clips.some(c => {
                        if (c.src === clip.src) {
                            position = c._position
                            found = true
                            return true
                        }
                        return false
                    })
                    return found
                })
            }
            if (position !== undefined) {
                console.log(player.state.currentTime, position, clipDuration(clip))
                if (player.state.currentTime < position ||
                    player.state.currentTime > position + clipDuration(clip)) {
                    //player.setCurrentTime(position)
                }
            }
        }
    }

    handleClick(event) {
        var checked = this.props.timeline.state.checked
        var value = event.target.value
        if (value === 'All') {
            checked = []
        } else if (checked.indexOf(value) > -1) {
            checked = checked.filter((v) => { return v !== value })
        } else {
            checked.push(value)
        }
        this.props.timeline.setState({
            checked: checked
        })
        this.setState({
            displayYaml: this.getYaml(checked)
        })
    }

    handleSubmit(event) {
        if (!this.state.className) {
            this.props.timeline.loadYaml(this.state.yaml, true)
        }
        event.preventDefault();
    }

    scrollToPosition() {
        var timeline = this.props.timeline
        var currentTime = timeline.props.player.state.currentTime
        // FIXME: if all use this
        var track = timeline.state.tracks.filter(t => { return t.stacked })[0]
        if (track && this.refs.text) {
            var currentClip = track.clips.filter(c => { return c._position <= currentTime }).pop()
            var source = currentClip.source
            var offset = 0
            this.refs.text.value.split('\n').some(l => { offset++; return l.indexOf(source) > -1})
            var height = offset * this.refs.text.scrollHeight / this.refs.text.value.split('\n').length
            this.refs.text.scrollTo(0, height - 150)
        }
    }

    render() {
        var disabled = this.state.className === 'invalid'
        var this_ = this
        return (
            <div className="yaml-editor">
                <form onSubmit={this.handleSubmit}>
                <textarea className={this.state.className} defaultValue={this.state.displayYaml} onChange={this.handleChange} key={this.props.timeline.state.yaml+this.props.timeline.state.checked} onClick={this.handleSeek} ref="text" />
                <input type="submit" value="Update" disabled={disabled} />
                <label><input type="checkbox" name="track" value="All" checked={this_.props.timeline.state.checked.length === 0} onChange={this_.handleClick} />All</label>
                {this.props.timeline.state.tracks.map(function(track, i) {
                    return <label key={track.name}><input type="checkbox" name="track" value={track.name} checked={this_.props.timeline.state.checked.indexOf(track.name) > -1} onChange={this_.handleClick} />{track.name}</label>
                })}
                </form>
            </div>
        )
    }
}

export { YamlEditor };
