import React from 'react';
import sanitizeHtml from 'sanitize-html';

/*
const addLineBreaks = string =>
  string.split('\n').map((text, index) => (
    <React.Fragment key={`${text}-${index}`}>
      {text}
      <br />
    </React.Fragment>
  ));

*/

function sanitizeHTML(string) {
    return {__html: sanitizeHtml(string)}
}

function addLineBreaks(string) {
    string = string.replace('\n', '<br>\n')
    return (<span dangerouslySetInnerHTML={sanitizeHTML(string)} />)
}

function isIOS() {
    if (
        window &&
        window.navigator &&
        /iPhone|iPad|iPod/i.test(window.navigator.userAgent)
    ) {
        return true
    } else {
        return false
    }
}

function isMobile() {
    if (
        window &&
        window.navigator &&
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(window.navigator.userAgent)
    ) {
        return true
    } else {
        return false
    }
}

function formatSeconds(position) {
    var hours   = Math.floor(position / 3600);
    var minutes = Math.floor((position - (hours * 3600)) / 60);
    var seconds = Math.floor(position - (hours * 3600) - (minutes * 60));
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}

export { addLineBreaks, sanitizeHTML, isIOS, isMobile, formatSeconds };
