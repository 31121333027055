import React, { Component, } from 'react';

import './about.scss';

class Short extends Component {
    render() {
        return (
            <div className="about">
                <h1>About</h1>
                <p>
                Use the pause button. Best explored on non-phones.
                </p>
            </div>
        )
    }
}

class About extends Component {
    render() {
        return (
            <div className="about">
            <div className="text">

<h1>About</h1>

<p>
Once upon a time (in Mumbaaai and elsewhere), historians were the ones who made themselves at home in archives, filmmakers made films, artists artworks and programmers programs, while journalists and activists did investigative research. There were occasional visits from one to the other, but they were characterised by a nervous knowledge of being outsiders.  
</p><p>
At <a href="https://studio.camp" target="blank_">CAMP</a> we have seen ourselves as transdisciplinary, and believed that imagination, scholarly research and interventionist inventions could take new shapes outside of academic and professional disciplines. We have wondered if the city we live and work in would look completely different if measured with different senses and instruments. Some of our earlier efforts, with others, at building infrastructure that could have similar ambitions live today as the online video archives, <a href="https://pad.ma" target="blank_">pad.ma</a> and <a href="https://indiancine.ma/" target="blank_">indiancine.ma</a>. 
</p><p>

<a href="#">Ghar.with.camp</a> is an attempt to tell a story made in a new way, drawing primarily from these two archives. It is published in a format that is simultaneously a screen, a collection, a set of references, and a talk. It was born as a series of live "video lectures" that we began to perform on our rooftop, and then on invitation in schools and exhibitions. It became web-based as a long-term collaborator developed new software, and as we developed a new set of "workflows". Which are also open invitations and proposals, on how to use shared archives. This work is made up of photographs, documents, film and video clips, interviews and outtakes, sounds and subtitles, jetsam and flotsam, that a group of friends and collaborators have been caring for in online and offline collections for the past decade. 
</p><p>

<b><i>Ghar Mein Shehar Hona</i>: City Housing in a Cultural Matrix (1950 to 2020)</b> is a story of Bombay, told as three approximately 90-minute episodes. It is also a universal story of the aspiration and struggles for housing, as they meet in the fantasies of profiteers, the records of municipalities, the protests of slum dwellers, and government promises. This is a city where the intensity of the housing issue is well-suited for an ethico-political, historical reckoning of the rights and powers of people who want to live here. Parts of the story have been told before: by journalists and academics, state propaganda and documentary filmmakers and occasionally by commercial cinema. Other parts have been forgotten. We wanted to share with you a non-finite, but relatively comprehensive attempt to tell a set of histories: For example of a place called <i>Janata</i> (People's) Colony that existed physically from 1950 to 1976, or the fights and fortunes of city organisations gathered around the housing question, starting in the early 1980s. For us these were backbones for understanding what happened here, and why the contemporary landscape looks like it does.
</p><p>

Like the archives it is built upon, this website likes to acknowledge sources, and is entirely non-commercial in intent. Its use of existing materials is in the spirit of promoting criticism and review, for all our education, and in compliance with fair dealing principles enshrined in the Indian Copyright Act. Via this particular interface (1), we hope there are other journeys that a curious viewer/user, and we ourselves, might embark upon. 
</p><p>

(1) Best viewed on a non-phone screen, pause shows related resources. 
</p><p>

Software: Jan Gerber<br />
For any further information or feedback please email us at contact_at_studio.camp

</p><p>

<i>Ghar Mein Shehar Hona</i> (Part 1) wishes to thank, in alphabetical order. 
</p><p>

Amit Moitra<br />
Anand Patwardhan<br />
Ashish Rajadhyaksha<br />
Ashoka Mehta<br />
Asiatic Society of Mumbai<br />
Ayub Sheikh<br />
B.D. Garga<br />
BUILD<br />
Blitz<br />
Bombay Chronicle<br />
C.D. Barfiwala<br />
Charles Correa<br />
Chetan Anand<br />
Chicago Architecture Biennial<br />
Chittaprosad<br />
Economic and Political Weekly<br />
Faiza Ahmad Khan<br />
Films Division<br />
Foundation for Arts Initiatives<br />
G.G. Mehta<br />
Geeta Thatra<br />
Gokhale Institute of Politics and Economics<br />
Guru Dutt<br />
Hussain Indorewala<br />
IPTA<br />
Iqbal<br />
Jagmohan<br />
Jockin Arputham<br />
Joshua Thomas<br />
K.A. Abbas<br />
Lawrence Liang<br />
Library of Tata Institute of Social Sciences<br />
Louis Malle<br />
Madan Naik<br />
Maharashtra State Archives<br />
Mani Kaul<br />
NCube<br />
Naezy<br />
National Archives of India<br />
National Film Archive of India<br />
Nehru Memorial Museum and Library<br />
Nirali Joshi<br />
Nusrat Jafri<br />
P.B. Pendharkar<br />
P.S. Abdul Hassan<br />
Pratap Pramar<br />
Pratap Sharma<br />
R.K. Munir<br />
Raj Kapoor<br />
Ramesh Saigal<br />
Ravi Prakash<br />
Residents of Cheetah Camp<br />
S.N.S Sastry<br />
S.Sukhdev<br />
Saeed Akhtar Mirza<br />
Sahapedia<br />
Sahir Ludhianvi<br />
Sai Pranjpye<br />
Shahid Jaipuri<br />
Shambhu Mitra<br />
Shikhar Goel<br />
Siddharth Kak<br />
Sumesh Sharma<br />
T.A. Abraham<br />
The Illustrated Weekly of India<br />
Trupti Kanade<br />
Vijay B. Chandra<br />
Viren Desai<br />
</p>





            <p className="nav">
                <a href="#">Back</a>
            </p>
            </div>
        </div>
        )

    }
}


export { About };
