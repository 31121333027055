import React, { Component, } from 'react';
import YAML from 'yaml';

import './timeline-editor.scss';


import { loadPlaylist, clipDuration, recomputeOverlapping } from './playlist';
import { YamlEditor } from './yaml-editor';
import { api } from './api';
import { freezeRanges, inFreeze, freezeTime, frozenPast } from './freeze';

const CLIP_KEYS = [
    'duration',
    'keyframes',
    'position',
    'source',
    'src',
    'style',
    'text',
    'context',
    'transform',
    'playbackRate',
    'volume',
    'volumeKeyframes',
    'freeze',
    'pause',
    'seekPoint',
    'chapterTitle',
]
const YAML_KEYS = ['volumeKeyframes', 'style', 'freeze']
const FLOAT_KEYS = ['volume', 'duration', 'position']

class Zoom extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var scale = parseFloat(event.target.value)
        this.props.timeline.setScale(scale)
        event.preventDefault();
    }
    render() {
        return (
            <div className="zoom">
            <input type="range" min="1" max="100" steps="0.1" value={this.props.timeline.state.scale}  onChange={this.handleChange} />
            </div>
        )
    }
}

const CONTEXT_PLACEHOLDER = 'loading new version on update...'

class ClipDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAll: false,
            pause: false,
            seekPoint: false
        }
        var this_ = this
        ;CLIP_KEYS.forEach(function(key) {
            if (props.clip && key in props.clip) {
                if (YAML_KEYS.indexOf(key) > -1) {
                    this_.state[key] = YAML.stringify(props.clip[key])
                } else {
                    this_.state[key] = props.clip[key]
                }
            }
        })
        this.handleChange = this.handleChange.bind(this);
        this.nextClip = this.nextClip.bind(this);
        this.previousClip = this.previousClip.bind(this);
        this.toggleShowAll = this.toggleShowAll.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateContext = this.updateContext.bind(this);
    }

    toggleShowAll(event) {
        this.setState({
            showAll: !this.state.showAll
        })
    }

    updateContext(selectedEntry) {
        var this_ = this;
        api('getContext', {text: this.state.text}, (response) => {
            console.log('got context', response)
            this_.setState({
                context: response.result.context
            }, () => {
                this_.props.timeline.edit(
                    selectedEntry[0],
                    selectedEntry[1], {
                        context: response.result.context
                    }
                )
            })
        })
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var state = {}
        //FIXME: add feedback if yaml values are invalid
        /*
        if (YAML_KEYS.indexOf(key) > -1) {
            try {
                state[event.target.name] = YAML.parse(event.target.value)
            } catch  {
                // add error style
            }
        }
        */
        state[name] = value
        if (name === 'text' && this.props.track.updateContext) {
            state['context'] = CONTEXT_PLACEHOLDER
        }
        this.setState(state)
    }

    cycle(direction) {
        var this_ = this
        var current = this.props.timeline.state.selectedEntry
        var next = current[1] + direction
        if (next >= this.props.track.clips.length) {
            next = 0
        }
        if (next < 0) {
            next = this.props.track.clips.length -1
        }
        this.props.timeline.setState({
            selectedEntry: [current[0], next]
        }, () => {
            var currentTime = this_.props.track.clips[next]._position
            this_.props.timeline.setCurrentTime(currentTime)
            this_.props.timeline.updateSelectedEntry()
        })
    }

    nextClip(event) {
        this.cycle(1)
    }

    previousClip(event) {
        this.cycle(-1)
    }

    handleSubmit(event) {
        var this_ = this
        var state = {}
        ;CLIP_KEYS.forEach((key) => {
            if (key in this_.state) {
                if (FLOAT_KEYS.indexOf(key) > -1) {
                    state[key] = parseFloat(this_.state[key])
                } else if (YAML_KEYS.indexOf(key) > -1) {
                    try {
                        state[key] = YAML.parse(this_.state[key])
                    } catch  {}
                } else {
                    state[key] = this_.state[key]
                }
            }
        })
        if (state.position === this.props.clip._position) {
            delete state.position
        }
        this.props.timeline.edit(
            this.props.timeline.state.selectedEntry[0],
            this.props.timeline.state.selectedEntry[1],
            state
        )
        if (this.state.context === CONTEXT_PLACEHOLDER) {
            this.updateContext(this.props.timeline.state.selectedEntry)
        }
        event.preventDefault();
    }
    render() {
        var style = {
            maxHeight: 'calc(100% - ' + this.props.timeline.height() + 'px)'
        }
        var playheadPosition = this.props.timeline.props.player.state.currentTime - this.props.clip._position
        if (this.state.playbackRate && this.state.playbackRate !== 1) {
            playheadPosition *= this.state.playbackRate
        }
        if (!this.props.timeline.props.player.state.paused) {
            playheadPosition = playheadPosition.toFixed(3)
        }
        if (this.state.freeze) {
            try {
                var freezes = freezeRanges(YAML.parse(this.state.freeze), this.state.duration)
                if (inFreeze(freezes, this.state.duration, playheadPosition)) {
                    playheadPosition = freezeTime(freezes, this.state.duration, playheadPosition)
                    playheadPosition += '(freeze)'
                }
            } catch {}
        }

        return (
            <div className="entry-details" style={style}>
                <div className="details-navigation">
                <button onClick={this.previousClip} >Previous</button><button onClick={this.nextClip} >Next</button>
                </div>
                <form onSubmit={this.handleSubmit}>
                <h2>{this.props.clip.type}</h2>
                {!this.props.track.stacked ? (
                <div>
                    Position: <input type="text" name="position" defaultValue={this.props.clip._position} onChange={this.handleChange} /><br />
                </div>
                ) : ""}
                {this.props.track.stacked ? (
                <div>
                    Position: <span>{this.props.clip._position}</span><br />
                </div>
                ) : ""}
                <div>
                    Playhead position in clip: <span>{playheadPosition}</span><br />
                </div>
                <div>
                    Duration: <input type="text" name="duration" value={this.state.duration} onChange={this.handleChange} /><br />
                </div>
                {this.state.source ? (
                <div>
                    Source: <input type="text" name="source" value={this.state.source} onChange={this.handleChange} /><br />
                </div>
                ) : ""}
                {this.state.src ? (
                <div>
                    Src: <input type="text" name="src" value={this.state.src} onChange={this.handleChange}/><br />
                </div>
                ) : ""}
                {this.props.track.stacked ? (
                <div>
                    <label>
                    <input
                        name="pause"
                        type="checkbox"
                        checked={this.state.pause}
                        onChange={this.handleChange}
                    />Pause after this clip</label>
                    <label><input
                        name="seekPoint"
                        type="checkbox"
                        checked={this.state.seekPoint}
                        onChange={this.handleChange}
                    />Seek Point</label>
                    <br />
                </div>
                ) : ""}
                {this.state.chapterTitle || this.state.seekPoint ? (
                    <div>
                        Chapter Title: <input type="text" name="chapterTitle" value={this.state.chapterTitle} onChange={this.handleChange}/><br />
                    </div>
                ) : ""}
                {this.state.text || ['text', 'title'].indexOf(this.props.clip.type) > -1  ? (
                    <div>
                    Text:<br />
                    <textarea name="text" value={this.state.text} onChange={this.handleChange} />
                    </div>
                ) : ""}
                {this.state.context ? (
                    <div>
                    Context (HTML):<br />
                    <textarea name="context" value={this.state.context} onChange={this.handleChange} />
                    </div>
                ) : ""}
                {this.state.transform || this.state.showAll ? (
                    <div>
                    Transform:
                    <input type="text" name="transform" value={this.state.transform}  onChange={this.handleChange} />
                    </div>
                ) : ""}
                {this.state.keyframes || (this.state.showAll && ['image', 'title', 'text'].indexOf(this.props.clip.type) > -1) ? (
                    <div>
                    Keyframes:<br />
                    <textarea name="keyframes" value={this.state.keyframes}  onChange={this.handleChange} />
                    </div>
                ) : ""}
                {this.state.style  || (this.state.showAll && ['text', 'video', 'image'].indexOf(this.props.clip.type) > -1) ? (
                    <div>
                    Style:<br />
                    <textarea name="style" value={this.state.style} onChange={this.handleChange} />
                    </div>
                ) : ""}
                {this.state.volume !== undefined || this.state.showAll ? (
                <div>
                    Volume: <input type="text" name="volume" value={this.state.volume} onChange={this.handleChange} /><br />
                </div>
                ) : ""}
                {this.state.volumeKeyframes || this.state.showAll ? (
                    <div>
                    Volume Keyframes:<br />
                    <textarea name="volumeKeyframes" value={this.state.volumeKeyframes} onChange={this.handleChange} />
                    </div>
                ) : ""}
                {this.state.playbackRate || this.state.showAll ? (
                    <div>
                    Playback Rate:
                    <input type="text" name="playbackRate" value={this.state.playbackRate}  onChange={this.handleChange} />
                    </div>
                ) : ""}
                {this.state.freeze || this.state.showAll ? (
                    <div>
                    Freeze Frame:<br />
                    <textarea name="freeze" value={this.state.freeze}  onChange={this.handleChange} />
                    </div>
                ) : ""}
                <input type="submit" value="Update" />
                <label><input type="checkbox" checked={this.state.showAll} onChange={this.toggleShowAll} />Show All</label>

                </form>
            </div>
        );
    }

}

ClipDetails.defaultProps = {
  entry: null,
}

class Entry extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        var this_ = this
        Object.keys(props).forEach(function(key) {
            this_.state[key] = props[key]
        })
        this.handleClick = this.handleClick.bind(this);
    }

    isSelected() {
        var selected = this.state.track.props.timeline.state.selectedEntry
        return (selected && selected[0] === this.props.track.props.idx && selected[1] === this.props.idx)
    }

    handleClick() {
        var this_ = this;
        this.state.track.props.timeline.setState({
            selectedEntry: this.isSelected() ? null : [this.props.track.props.idx, this.props.idx]
        }, () => {
            var timeline = this_.state.track.props.timeline
            var cT = timeline.props.player.state.currentTime
            if (!(cT >= this_.state._position && cT < this_.state._position + this_.state.duration)) {
                timeline.props.player.setCurrentTime(this_.state._position)
            }
            timeline.updateSelectedEntry()
        })
    }

    getKey() {
        return this.props.track._reactInternalFiber.key + ':' + this._reactInternalFiber.key
    }

    render() {
        let timeline = this.state.track.props.timeline
        let width = timeline.scale(clipDuration(this.state))
        let left = timeline.scale(this.state._position)
        const style = {
            width: width + 'px',
            left: left + 'px',
        }
        let text = 'source' in this.state ? this.state.source.replace('https://', '').replace('/editor', '') : this.state.type
        var title = this.state.src
        if (['title', 'text'].indexOf(this.state.type) > -1) {
            text  = this.state.text
            title = text
        }
        var className = "clip " + this.props.type
        if (this.isSelected()) {
            className += " selected"
        }
        if (this.props._overlapping) {
            className += " overlapping"
        }
        return (
            <div className={className} style={style} title={title} onClick={this.handleClick}>
                {text}
            </div>
        );
    }
}

Entry.defaultProps = {
    type: 'black',
    track: null
}

class Track extends Component {
  render() {
    var track = this
    let width = this.props.timeline.scale(this.props.timeline.state.duration)
    const style = {
        width: width + 'px'
    }
    return (
        <div className="track" title={ this.props.name } style={style}>
            {this.props.clips.map(function(clip, i) {
                return <Entry {...clip} track={track} idx={i} key={YAML.stringify(clip)} />
            })}
        </div>
    );
  }
}

Track.defaultProps = {
  name: 'Track',
  clips: [],
  timeline: null,
  active: false
}

class PlayHead extends Component {
    componentDidUpdate() {
        /*
        if (!this.props.timeline.props.player.state.paused) {
            var scrollLeft = this.props.timeline.scale(this.props.timeline.props.player.state.currentTime)
            scrollLeft -= window.innerWidth / 2
            this.refs.playhead.parentElement.parentElement.scrollLeft = scrollLeft
        }
        */
    }

    render() {
        var left = this.props.timeline.scale(this.props.timeline.props.player.state.currentTime)
        var style = {
            left: left + 'px'
        }
        return (
            <div className="playhead" style={style} ref="playhead"></div>
        )
    }
}

class Position extends Component {
    constructor(props) {
        super(props);
        this.handleSeek = this.handleSeek.bind(this);
    }

    handleSeek(event) {
        var position = event.clientX + this.refs.position.parentElement.scrollLeft - 16
        position = this.props.timeline.unscale(position)
        this.props.timeline.props.player.setCurrentTime(position)
        this.props.timeline.scrollToPosition(position)
        event.preventDefault();
    }

    render() {
        let width = this.props.timeline.scale(this.props.timeline.state.duration)
        var style = {
            width: width + 'px'
        }
        return (
            <div className="position" onClick={this.handleSeek} style={style} ref="position" title="click to seek">
                <PlayHead timeline={this.props.timeline} />
            </div>
        )
    }
}

class Timeline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tracks: [],
            duration: 0,
            selectedEntry: null,
            checked: [],
            scale: 100,
        }
        this.loadYaml = this.loadYaml.bind(this);
        this.edit = this.edit.bind(this);
        this.loadURL()
        this.props.player.timeline = this
        window.timeline = this
    }

    loadURL() {
        fetch(this.props.yaml + '?' + +new Date()).then(response => {
            if (response.status !== 200) {
                console.log('failed to load', response)
            } else {
                response.text().then(this.loadYaml)
            }
        })
    }

    loadYaml(text, update) {
        var playlist = loadPlaylist(text)
        this.setState({
            yaml: text,
            tracks: playlist.tracks,
            duration: playlist.duration,
        }, () => {
            this.props.player.loadYaml(this.state.yaml)
            update && api('update', {id: this.props.player.props.project, timeline: this.state.yaml})
            this.scrollToPosition(this.props.player.state.currentTime)
            var k = this.props.player.props.project + '_selectedEntry'
            if (k in window.localStorage) {
                try {
                    this.setState({
                        selectedEntry: JSON.parse(window.localStorage[k])
                    })
                } catch {
                    console.log(window.localStorage[k])
                    delete window.localStorage[k]
                }
            }
        })
    }

    updateSelectedEntry() {
        var k = this.props.player.props.project + '_selectedEntry'
        if (this.state.selectedEntry) {
            window.localStorage[k] = JSON.stringify(this.state.selectedEntry)
        } else if (k in window.localStorage) {
            delete window.localStorage[k]
        }
    }

    edit(track_idx, entry_idx, state) {
        var this_ = this
        var duration = this.state.duration
        var updatePosition = false
        var delta = 0, deltaFrom = 0

        CLIP_KEYS.forEach((key) => {
            if (key in state) {
                if (this_.state.tracks[track_idx].clips[entry_idx][key] !== state[key]) {
                    var oldDuration = clipDuration(this_.state.tracks[track_idx].clips[entry_idx])
                    if (['position'].indexOf(key) > -1) {
                        updatePosition = true
                        deltaFrom = this_.state.tracks[track_idx].clips[entry_idx]._position
                        delta = state[key] - this_.state.tracks[track_idx].clips[entry_idx][key]
                        deltaFrom += delta
                    } else if (['freeze'].indexOf(key) > -1) {
                        deltaFrom = this_.state.tracks[track_idx].clips[entry_idx]._position
                        /*
                        if (Object.keys(state[key]).length === 1) {
                            deltaFrom += Object.keys(state[key])[0]
                        } else {
                            deltaFrom += oldDuration
                        }
                        */
                        deltaFrom += oldDuration
                    } else if (['duration', 'playbackRate'].indexOf(key) > -1) {
                        deltaFrom = this_.state.tracks[track_idx].clips[entry_idx]._position
                        deltaFrom += oldDuration
                    }
                    if (key == 'volumeKeyframes' && state[key] === null) {
                        delete this_.state.tracks[track_idx].clips[entry_idx][key]
                    } else {
                        this_.state.tracks[track_idx].clips[entry_idx][key] = state[key]
                    }
                    var newDuration = clipDuration(this_.state.tracks[track_idx].clips[entry_idx])
                    if (oldDuration !== newDuration) {
                        delta = newDuration - oldDuration
                        updatePosition = true
                        if (deltaFrom === 0) {
                            deltaFrom = this_.state.tracks[track_idx].clips[entry_idx]._position
                        }
                    }
                }
            }
        })
        if (updatePosition) {
            var position = 0
            this_.state.tracks[track_idx].clips.forEach(clip => {
                // fixme shift positions too
                clip._position = !clip.position ? position : clip.position
                position = clip._position + clipDuration(clip)
            })
            duration = Math.max(duration, position)
            if (this_.state.tracks[track_idx].stacked && delta) {
                this_.state.tracks.forEach((track) => {
                    if (track.idx !== track_idx && !track.stacked) {
                        track.clips.forEach((clip) => {
                            if (clip._position > deltaFrom && clip.position) {
                                clip.position += delta
                                if (clip.position < 0) {
                                    clip.position = 0
                                }
                                clip._position = clip.position
                            }
                        })
                    }
                })
            }
        }

        recomputeOverlapping(this_.state.tracks)
        this.setState({
            yaml: this.dumpYaml(),
            duration: duration
        }, () => {
            this.props.player.loadYaml(this.state.yaml)
            api('update', {id: this.props.player.props.project, timeline: this.state.yaml})
        })
    }

    dumpYaml() {
        var tracks = YAML.parse(YAML.stringify(this.state.tracks))
        tracks.forEach((track) => {
            delete track.idx
            track.clips.forEach((clip) => {
                delete clip._position
                if ('_dashPosition' in clip) {
                    delete clip._dashPosition
                }
                if (track.stacked && 'position' in clip) {
                    delete clip.position
                }
                if ('pause' in clip && !clip.pause) {
                    delete clip.pause
                }
                if ('seekPoint' in clip && !clip.seekPoint) {
                    delete clip.seekPoint
                }
                if ('freeze' in clip && !clip.freeze) {
                    delete clip.freeze
                }
                if ('_overlapping' in clip) {
                    delete clip._overlapping
                }
            })
        })
        return YAML.stringify(tracks)
    }

    shiftTrack(name, offset) {
        this.state.tracks.forEach((track) => {
            if (track.name === name && !track.stacked) {
                track.clips.forEach((clip) => {
                    clip.position += offset
                    clip._position = clip.position
                })
            }
        })
        this.setState({
            yaml: this.dumpYaml(),
        }, () => {
            this.props.player.loadYaml(this.state.yaml)
            api('update', {id: this.props.player.props.project, timeline: this.state.yaml})
        })

    }

    scale(pixels) {
        let fps = this.props.fps
        /*
        let duration = this.state.duration
        // scale = 100 is one pixel per frame
        // scale = 0 screen width = timeline
        var scale = fps * duration/(100-this.state.scale)
        return pixels * scale
        */
        return pixels * fps * (1/(101-this.state.scale))
    }

    unscale(pixels) {
        let fps = this.props.fps
        return pixels / (fps * (1/(101-this.state.scale)))
    }

    setScale(scale) {
        this.setState({
            scale: scale
        }, () => {
            this.scrollToPosition(this.props.player.state.currentTime)
        })
    }


    scrollToPosition(position) {
        var scrollLeft = this.scale(position)
        scrollLeft -= window.innerWidth / 2
        this.refs.timeline.scrollLeft = scrollLeft
        //console.log(this.refs.timeline, this.refs.timeline.scrollLeft, scrollLeft, position)
        console.log('!! scroll?', this._editor)
        if (this._editor) {
            this._editor.scrollToPosition()
        }
    }

    setCurrentTime(currentTime) {
        this.props.player.setCurrentTime(currentTime)
        this.scrollToPosition(currentTime)
    }

    getSelectedClip() {
        var track_idx = this.state.selectedEntry[0]
        var idx = this.state.selectedEntry[1]
        //console.log(track_idx, idx, this.state.tracks[track_idx].clips[idx])
        return this.state.tracks[track_idx].clips[idx]
    }

    height() {
        if (this.state.tracks) {
            return Object.keys(this.state.tracks).length * 32 + 20 + 60
        } else {
            return 0
        }
    }

    render() {
        var this_ = this
        var details = ''
        var selected
        if (this.state.selectedEntry) {
            selected = this.getSelectedClip()
        }
        if (selected) {
            details = (<ClipDetails clip={selected} timeline={this} track={this.state.tracks[this.state.selectedEntry[0]]} key={this.state.selectedEntry} />);
        } else {
            details = (<YamlEditor timeline={this} key={this.state.yaml} /> );
        }
        // <div className="position"><input value={this.props.player.state.currentTime} /></div>
        return (
            <div className="timeline-box">
                <Zoom timeline={this} />
                <div className="label">
                    <div className="tracks">
                        {this.state.tracks.map(function(track, i) {
                            return <div className="track" key={i} title={track.name}>{track.name[0]}</div>
                        })}
                        <div className="seek" />
                    </div>
                </div>
                <div className="timeline" ref="timeline">
                    <Position timeline={this} />
                    <div className="tracks">
                        {this.state.tracks.map(function(track, i) {
                            return <Track {...track} timeline={this_} key={i} />
                        })}
                    </div>
                </div>
                {details}
            </div>
        );
    }
}

Timeline.defaultProps = {
  yaml: '/cache/timeline.yaml',
  fps: 25
}

export { Timeline };

/*
 
soomTimeline(scale) {
    var offset = 100*(1-scale)
    style.transform = 'scale3d('+ scale + ', ' + scale + ',1) translate3d(-' + offfset + '%, 0, 0)';
}

 */
 


