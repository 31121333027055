
import { api } from './api';

function reportErrors() {
    if (typeof Error.stackTraceLimit === 'number') {
        Error.stackTraceLimit = Math.max(50, Error.stackTraceLimit);
    }
    window.onerror = function(error, url, line, column, errorObj) {
        console.log('error', error, url, line, column, errorObj)
        if (error === 'TypeError: Attempted to assign to readonly property.') {
            return;
        }
        try {
            var stack = (errorObj && errorObj.stack) || "(no stack trace available)";

            !/^resource:/.test(url) && api('error', {
                text: error + "\n\n" + stack,
                url: document.location.pathname + ' at ' + url,
                line: line
            });
        } catch(e) {}
    };
}

export { reportErrors };
