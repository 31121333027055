import React, { Component, } from 'react';

import './second-screen.scss';

import { Track, Text } from './timeline-player';
import { loadPlaylist } from './playlist';
import { reportErrors } from './logging';


function getOrigin(url) {
    return url.split('/').slice(0, 3).join('/')
}

function getPath(url) {
    return '/' + url.split('/').slice(3).join('/')
}

class OverlayTrack extends Track {
    constructor(props) {
        super(props);
        this.current = ''
    }

    postMessage(event, data) {
        this.refs.external.contentWindow.postMessage(JSON.stringify({
            event: event, data: data
        }), '*')
    }

    componentDidUpdate() {
        if (this.state.currentClip && this.current !== this.state.currentClip.text) {
            var delay = 2000
            var next = this.state.currentClip.text.trim().split(' ')[0]
            if (this.current.slice(0, 4) === 'http') {
                console.log('next', getOrigin(next), getOrigin(this.refs.external.src))
                if (getOrigin(this.refs.external.src) === getOrigin(next)) {
                    console.log('update pandora', next, getPath(next), 'from', this.refs.external.src)
                    this.postMessage('url', {
                        url: getPath(next)
                    })
                    delay = 500
                } else {
                    console.log('switch url (from url)', next)
                    this.refs.external.src = next
                }
            } else {
                console.log('switch url (from text)', next)
                this.refs.external.src = next
            }
            this.current = next
            if (this.current.slice(0, 4) === 'http' && this.current.match(/\/player\//)) {
                setTimeout(function(track) {
                    return function() {
                        track.postMessage('play', {})
                    }
                }(this), delay)
            }
        }
    }

    render() {
        var style = {}
        var textStyle = {}
        if (this.state.currentClip) {
            style.display = this.state.currentClip.text.slice(0, 4) === 'http' ? 'block' : 'none'
            textStyle.display = !style.display
        } else {
            style.display = 'none'
            textStyle.display = 'none'
        }
        return (
            <div>
            <iframe
                ref="external"
                title="external"
                className="inline"
                style={style}
            ></iframe>
            {this.state.currentClip ?
                <Text
                    {...this.state.currentClip}
                    className="title"
                    player={this.props.player}
                    track={this}
                    style={textStyle}
                /> : ''
            }
            </div>
        )
    }
}

class SecondScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            prefix: '/media/',
            tracks: [],
            yamlData: '',
            currentTime: this.props.startTime,
            duration: 0,
            paused: true
        }
        this.interval = null
        this.start = null
        this.initialPosition = this.props.startTime
        this.ticks = []
        this.loadYaml = this.loadYaml.bind(this);
        this.tick = this.tick.bind(this);
        this.registerTick = this.registerTick.bind(this);
        this.onResize = this.onResize.bind(this);
        this.loadURL()
        window.addEventListener('resize', this.onResize, false);
        window.addEventListener('keydown', this.onKeyDown, false);
        window.player = this
        reportErrors()
        document.body.classList.add('hide-mouse')
        this.connectWS()
    }

    loadURL() {
        fetch(this.props.yaml + '?' + +new Date()).then(response => {
            if (response.status !== 200) {
                console.log('failed to load', response)
            } else {
                response.text().then(this.loadYaml)
            }
        })
    }

    reloadURL() {
        var this_ = this
        fetch(this.props.yaml + '?' + +new Date()).then(response => {
            if (response.status !== 200) {
                console.log('failed to load', response)
            } else {
                response.text().then((text) => {
                    if (text !== this_.state.yamlData) {
                        this._loadYaml(text)
                    }
                })
            }
        })
    }

    loadYaml(text) {
        var this_ = this
        var playlist = loadPlaylist(text)
        this.setState({
            yamlData: text,
            tracks: playlist.tracks,
            duration: playlist.duration,
            seekPoints: playlist.seekPoints
        }, () => {
            this_.tick(this_.state.currentTime)
        })
    }

    connectWS(init) {
        var this_ = this;
        var url = document.location.origin.replace('https://', 'wss://').replace('http://', 'ws://') + '/ws/'
        this.ws = new WebSocket(url)
        this.ws.onmessage = (event) => {
            var data 
            try {
                data = JSON.parse(event.data)
            } catch {
                data = null
            }
            if (data) {
                var action = data[0]
                data = data[1]
                if (action === 'currentTime') {
                    //console.log('ct', action, data)
                    this_.setCurrentTime(data)
                } else if (action === 'reload') {
                    console.log('reload page')
                    document.location.reload()
                } else if (action === 'reload-yaml') {
                    this_.reloadURL()
                } else {
                    console.log('!!', action, data)
                }
            }

        }
        this.ws.onopen = (event) => {
            console.log('ws connected')
            this_.postMessage('open', this_.props.project)
        }
        this.ws.onerror = (event) => { this_.ws.close() }
        this.ws.onclose = (event) => { setTimeout(() => { this_.connectWS() }, 500) }
    }

    postMessage(action, data) {
        if (this.ws) {
            this.ws.send(JSON.stringify([action, data]))
        }
    }


    setCurrentTime(currentTime) {
        this.setState({
            currentTime: currentTime
        }, () => {
            this.ticks.forEach(tick => { tick(currentTime) })
        })
    }
    tick(currentTime) {
        //var now = new Date()
    }
    registerTick(tick) {
        this.ticks.push(tick)
    }

    onResize(event) {
        this.setState({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        })
    }

    render() {
        var this_ = this;
        var overlayTracks = this.state.tracks.filter(track => { return track.type === 'overlay' })
        var style = {}
        var className = 'overlay'

        /*
        var videoWidth = 1080
        var videoHeight = 1920
        var playerWidth = this.state.windowWidth
        var playerHeight = this.state.windowHeight
        if (this.props.timeline) {
            playerWidth = videoWidth / 2
            playerHeight = videoHeight / 2
        }
        var ratio = playerWidth / playerHeight
        var frameRatio = videoWidth / videoHeight
        var heightRatio = playerHeight / videoHeight
        var widthRatio = playerWidth / videoWidth
        var scale =  Math.min(heightRatio, widthRatio)
        var frameWidth = videoWidth * scale
        var frameHeight = videoHeight * scale
        style.transform = "scale(" + scale + ")"
        if (frameRatio >= ratio) {
            style.top = 'calc((' + playerHeight + 'px - ' + frameHeight + 'px) / 2)'
        } else {
            style.left = 'calc(('+ playerWidth + 'px - ' + frameWidth + 'px) / 2)'
        }
        */
        return (
            <div className="frame">
                <div className={className} onClick={this.handleClick} style={style}>
                    {overlayTracks.reverse().map(function(track) {
                        return <OverlayTrack player={this_} track={track.idx} key={track.idx} />
                    })}
                </div>
            </div>
        )
    }
}

SecondScreen.defaultProps = {
  yaml: '/cache/timeline.yaml',
  startTime: 0,
  fps: 25
}

export { SecondScreen };
