
function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function api(method, params, callback) {
    if (!callback && typeof(params) == 'function') {
        callback = params
        params = {}
    }
    var request = new XMLHttpRequest()
    request.addEventListener('load', function (event) {
        var response
        try {
            response = JSON.parse(event.target.responseText)
        } catch(e) {
            response = {
                error: {code: -32700, message: 'Parse error'}
            }
        }
        callback && callback(response)
    }, false)
    request.addEventListener('error', function (evt) {
        callback && callback({error: {code: -32000, message: 'Server error'}})
    }, false)
    request.open('POST', '/api/')
    request.setRequestHeader('Content-type', 'application/json')
    var csrftoken = getCookie('csrftoken')
    if (csrftoken) {
        request.setRequestHeader('X-CSRFToken', csrftoken)
    }
    request.send(JSON.stringify(
        {method: method, params: params, jsonrpc: '2.0'}
    ))
}

export { api };
