import React, { Component, } from 'react';
import styled, { keyframes } from 'styled-components'

import './timeline-player.scss';

import { Timeline } from './timeline-editor';
import { loadPlaylist, clipDuration } from './playlist';
import { addLineBreaks } from './utils';
import { DASH } from './dash-media';
import { reportErrors } from './logging';
import { PlayerOverlay } from './overlay';

class Track extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentClip: null,
            nextClip: null,
        }
        this.tick = this.tick.bind(this);
        this.getConfig = this.getConfig.bind(this);
        this.props.player.registerTick(this.tick)
        this.nextClipTimeout = null
        this.justPaused = false
    }

    getConfig() {
        return this.props.player.state.tracks[this.props.track]
    }

    cacheNextClip() {
        if (this.state.nextClip) {
            var this_ = this
            var delay = 250
            /*
            var delay = this.state.currentClip
                ? Math.max(((clipDuration(this.state.currentClip) -1) * 1000), 50)
                : 50;
            */
            this.nextClipTimeout && clearTimeout(this.nextClipTimeout)
            this.nextClipTimeout = setTimeout(function() {
                if (this_.state.nextClip) {
                    if (this_.state.nextClip.type === 'image') {
                        this_.cache = document.createElement('img')
                        this_.cache.src = this_.state.nextClip.prefix + this_.state.nextClip.src
                    } else if (this_.state.nextClip.type === 'video') {
                        this_.cache = document.createElement('video')
                        this_.cache.src = this_.state.nextClip.prefix + this_.state.nextClip.src
                    }
                }
                this_.nextClipTimeout = null
            }, delay)
        }
    }

    tick(currentTime) {
        var this_ = this;
        var currentClip = null
        var nextClip = null
        var track = this.getConfig()
        if (currentTime === undefined) {
            currentTime = this.props.player.state.currentTime
        }
        if (track) {
            track.clips.some(clip => {
                var out = clip._position + clipDuration(clip)
                if (out <= clip._position) {
                    return false
                }
                if (currentClip) {
                    nextClip = clip
                    nextClip.prefix = this_.props.player.state.prefix
                    return true
                }
                if (currentTime >= clip._position && currentTime < out) {
                    currentClip = clip
                    currentClip.prefix = this_.props.player.state.prefix
                }
                return false
            })
        }
        if (
            currentClip &&
            currentClip.pause &&
            !this.justPaused &&
            !this_.props.player.props.broadcast
        ) {
            if (Math.abs(currentTime - (currentClip._position + clipDuration(currentClip))) < 0.1) {
                this.justPaused = true
                this.props.player.pause()
                //console.log('pause at end')
            }
        }
        if (!nextClip && currentClip && track.clips.length) {
            nextClip = track.clips[0]
            nextClip.prefix = this_.props.player.state.prefix
        }
        if (this.state.currentClip !== currentClip) {
            this.setState({
                currentClip: currentClip,
                nextClip: nextClip
            }, () => {
                //console.log('activate', currentTime, currentClip)
                this.cacheNextClip()
                this.justPaused = false
                //console.log('reset paused')
            })
        }
    }
}

class Text extends Component {
    constructor(props) {
        super(props);
        this.animationDelay = 0
        var animation = keyframes`${this.props.keyframes}`
        this.Div = styled.div`
            animation: ${animation} ${this.props.duration}s normal forwards;
        `
    }
    componentDidMount() {
        //console.log('text mounted')
    }

    componentDidUpdate() {
        //console.log('text update')
    }

    render() {
        var track = this.props.track.getConfig()
        var delay = this.animationDelay
        var key = [this.props.text, delay, this.props.style, this.props.keyframes].map(JSON.stringify).join('')
        if (this.props.player.state.paused) {
            this.animationDelay = delay = - (this.props.player.state.currentTime - this.props.position)
        }
        delay = Math.round(delay * 1000)
        var style = {
            animationIterationCount: 1,
            // jumps, only use for rendering
            'animationDelay': '' + delay + 'ms'
        }
        if (this.props.player.state.paused) {
            style['animationPlayState'] = 'paused'
        }
        if ((this.props.player.state.showOverlay || this.props.player.state.paused) && track.name === 'Subtitles') {
            style['height'] = 'calc(100% - 48px - 64px)'
        }

        var className = 'className' in this.props ? this.props.className : 'text'
        if (track.style) {
            Object.keys(track.style).forEach(key => {
                style[key] = track.style[key]
            })
        }
        if (this.props.style) {
            Object.keys(this.props.style).forEach(key => {
                var sKey = key.replace(/-./, (k) => { return k[1].toUpperCase() })
                style[sKey] = this.props.style[key]
            })
        }
        return (
            <this.Div className={className} style={style} ref="div" key={key}>
                {addLineBreaks(this.props.text)}
            </this.Div>
        );
    }
}
Text.defaultProps = {
  className: 'text',
}

class TextTrack extends Track {
    render() {
        if (this.state.currentClip && !this.props.player.isSeeking()) {
            var key = [
                this.state.currentClip.text, this.state.currentClip.style, this.state.currentClip.keyframes
            ].map(JSON.stringify).join('')
            return (
                <Text
                    {...this.state.currentClip}
                    player={this.props.player}
                    track={this}
                    key={key}
                />
            );
        }  else {
            return ""
        }
    }
}

class TitleTrack extends Track {
    render() {
        if (this.state.currentClip && !this.props.player.isSeeking()) {
            var key = [
                this.state.currentClip.text, this.state.currentClip.style, this.state.currentClip.keyframes
            ].map(JSON.stringify).join('')
            return (
                <Text
                    {...this.state.currentClip}
                    className="title"
                    player={this.props.player}
                    track={this}
                    key={key}
                />
            );
        }  else {
            return ""
        }
    }
}


class VideoTrack extends Track {
    renderClip() {
        if (this.state.currentClip) {
            if (this.state.currentClip.type === 'image') {
                return (
                    <Image
                        {...this.state.currentClip}
                        player={this.props.player}
                        track={this}
                        key={this.state.currentClip.src+this.state.currentClip.keyframes}
                    />
                );
            } else if (this.state.currentClip.type === 'video') {
                var track = this.getConfig()
                if (track.stacked && track.mpd) {
                    return ""
                } else {
                    return (
                        <Video {...this.state.currentClip} player={this.props.player} track={this} />
                    );
                }
            } else if (this.state.currentClip.type === 'audio') {
                return (
                    <Audio {...this.state.currentClip} player={this.props.player} track={this} />
                );
            } else if (this.state.currentClip.type === 'black') {
                return ""
            } else {
                console.log('unknown clip type', this.state.currentClip)
                return ""
            }
        }  else {
            return ""
        }
    }
    render() {
        var track = this.getConfig()
        if (track.mpd) {
            return (
                <div>
                    <div className="video crop">
                        <DASH
                            player={this.props.player}
                            mpd={this.props.player.getMPD()}
                            track={track}
                        />
                    </div>
                    {this.renderClip()}
                </div>
            )
        } else {
            return this.renderClip()
        }
    }
}

class Image extends Component {
    constructor(props) {
        super(props);
        this.animationDelay = 0
        var animation = keyframes`${this.props.keyframes}`
        this.Img = styled.img`
            animation: ${animation} ${this.props.duration}s normal forwards;
        `
    }
    componentDidMount() {
        //console.log('image mounted')
    }

    componentDidUpdate() {
        //console.log('image update')
    }

    render() {
        var delay = this.animationDelay
        if (this.props.player.state.paused) {
            this.animationDelay = delay = - (this.props.player.state.currentTime - this.props.position)
        }
        delay = Math.round(delay * 1000)
        var style = {
            animationIterationCount: 1,
            // jumps, only use for rendering
            'animationDelay': '' + delay + 'ms'
        }
        if (this.props.style) {
            Object.keys(this.props.style).forEach(key => {
                var sKey = key.replace(/-./, (k) => { return k[1].toUpperCase() })
                style[sKey] = this.props.style[key]
            })
        }
        if (this.props.player.state.paused) {
            style['animationPlayState'] = 'paused'
        }
        return (
            <div className="image crop">
                <this.Img src={this.props.prefix + this.props.src} style={style} ref="img" key={this.props.src+delay} />
            </div>
        );
    }
}

class Media extends Component {
    constructor(props) {
        super(props);
        this.seeked = this.seeked.bind(this);
        this.props.player.registerTick(this.seeked)
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate() { this.update(); }

    update() {}

    seeked() {
        if (this.props.player.seeked) {
            this.update()
        }
    }

    computeVolume() {
        if (this.props.player.state.muted) {
            return 0
        }
        var volume = 1
        var track = this.props.track.getConfig()
        if ('volumeKeyframes' in this.props && this.props.volumeKeyframes !== null) {
            var currentTime = this.refs.media.currentTime
            var keyframes = this.props.volumeKeyframes
            var start = Object.keys(keyframes).filter((p) => { return p <= currentTime })
            var end = Object.keys(keyframes).filter((p) => { return p > currentTime })
            if (!start.length && end.length) {
                start.push(end.pop(0))
                end = []
            }
            if (start.length && end.length) {
                start = start[start.length-1]
                end = end[0]
                var delta = parseFloat(keyframes[end]) - parseFloat(keyframes[start])
                var offset = (currentTime - start) / (end - start)
                volume = parseFloat(keyframes[start]) + offset*delta
                if (volume < 0) {
                    volume = 0
                } else if (volume > 1) {
                    volume = 1
                }
            } else if (start.length) {
                volume = parseFloat(keyframes[start[start.length-1]])
            } else {
                console.log('wtf', currentTime, start, end, this.props.volume)
            }
        } else if ('volume' in this.props) {
            volume = Math.max(Math.min(1, parseFloat(this.props.volume)), 0)
        } else if (track && 'volume' in track) {
            volume = Math.max(Math.min(1, parseFloat(track.volume)), 0)
        }
        volume = this.props.player.state.volume * volume
        if (this.props.player.state.speak) {
            volume *= 0.2
        }
        return volume
    }

    freezeRanges() {
        var freezes = this.props.freeze
        var duration = this.props.duration
        var tp = 0, vp = 0, ranges = [];
        Object.keys(freezes).forEach(freeze => {
            if (freeze > vp) {
                var d = freeze - vp
                ranges.push(['v', tp, tp + d, vp])
                tp += d
                vp += d
            }
            ranges.push(['f', tp, tp+freezes[freeze]])
            tp += freezes[freeze]
        })
        if (vp < duration) {
            var d = duration - vp
            ranges.push(['v', tp, tp + d, vp])
        }
        return ranges
    }


    inFreeze(currentTime) {
        if (!this.props.freeze) {
            return false
        }
        var ranges = this.freezeRanges(), inFreeze = false
        ranges.some(range => {
            //console.log(range[1], currentTime, range[2])
            if (currentTime >= range[1] && currentTime < range[2]) {
                inFreeze = range[0] === 'f'
                //console.log('current', range, currentTime)
                return true
            }
            return false
        })
        //console.log(currentTime, ranges, inFreeze)
        return inFreeze
    }

    frozenPast(currentTime) {
        var ranges = this.freezeRanges(), past = 0
        ranges.some(range => {
            if (range[1] < currentTime) {
                if (range[0] === 'f') {
                    past += range[2] - range[1]
                }
                return false
            }
            return true
        })
        //console.log('past', currentTime, past)
        return past
    }

    updateMediaState() {
        var player = this.props.player
        var element = this.refs.media
        if (element === undefined) {
            return
        }

        if (player.state.paused && !element.paused) {
            element.pause()
        } else if (!player.state.paused && element.paused && !element.ended) {
            element.play()
        }
        var volume = this.computeVolume()
        if (volume !== element.volume) {
            element.volume = volume
        }
        var playbackRate = this.props.playbackRate || 1
        if (playbackRate !== element.playbackRate) {
            element.playbackRate = playbackRate
        }

        var currentTime = player.state.currentTime - this.props._position
        var freeze = this.inFreeze(currentTime)
        if (freeze !== false) {
            if (!this.refs.media.paused) {
                element.muted = true
                element.pause()
            }
            this.frozen = true
            currentTime = freeze
        } else if (this.frozen) {
            if (!player.state.paused) {
                element.play()
                element.muted = false
            }
            this.frozen = false
        }
        if (this.props.freeze && freeze === false) {
            currentTime -= this.frozenPast(currentTime)
        }
        if (playbackRate !== 1) {
            //console.log('adjust for playback rate', playbackRate)
            currentTime /= playbackRate
        }
        // fixme: adjust current time if needed?
        /*
        if (
            Math.abs(currentTime - element.currentTime) > 1 &&
            element.duration && !element.seeking &&
            element.currentTime > 0 && currentTime >= 0
        ) {
            console.log('out of sync', element.src.split('/').pop(),
                'e.cT', element.currentTime,
                'cT', currentTime,
                'delta', currentTime - element.currentTime,
                'd', element.duration, element.seeking, element.readyState)
        }
        */

        if(this.props.track.justPaused) {
            return
        }

        // seek

        if ((player.seeked || (player.state.paused && !player.seeking)) && currentTime >= 0) {
            var src = element.src.split('/').pop()
            //console.log(src, this.props.src, player.seeking)
            //console.log('paused out of sync', element.currentTime, currentTime, element.src)

            //if (element.duration && Math.abs(currentTime - element.currentTime) > 1) {
            if (player.seeked || Math.abs(currentTime - element.currentTime) > 1) {
                console.log('adjust time', src, element.currentTime, '=>', currentTime)
                element.currentTime = currentTime
            }
        }
    }
}


class Video extends Media {
    constructor(props) {
        super(props);
        this.state = {}
        this.handleClick = this.handleClick.bind(this);
    }

    update() {
        if (this.refs.media === undefined) {
            return
        }
        this.refs.media.setAttribute('playsinline', '1');
        //this.refs.media.setAttribute('autoplay', '1');
        this.updateMediaState()
    }

    handleClick(event) {
        event.preventDefault();
    }

    render() {
        var style = {}
        if (this.props.style) {
            Object.keys(this.props.style).forEach(key => {
                var sKey = key.replace(/-./, (k) => { return k[1].toUpperCase() })
                style[sKey] = this.props.style[key]
            })
        }
        if (this.props.transform) {
            style['transform'] = this.props.transform
        }
        return (
            <div className="video crop">
                <video src={this.props.prefix + this.props.src} ref="media" onClick={this.handleClick} style={style} />
            </div>
        )
    }
}


class AudioTrack extends Track {
    render() {
        var track = this.getConfig()
        if (track.mpd) {
            return (
                <div>
                    <div className="audio">
                        <DASH
                            player={this.props.player}
                            mpd={this.props.player.getMPD('audio')}
                            track={track}
                        />
                    </div>
                </div>
            )
        } else if (this.state.currentClip) {
            return (
                <Audio {...this.state.currentClip} player={this.props.player} track={this} />
            );
        }  else {
            return ""
        }
    }
}

class Audio extends Media {

    update() {
        //this.refs.media.setAttribute('autoplay', '1');
        this.updateMediaState()
    }

    render() {
        var style = {}
        if (this.props.transform) {
            style['transform'] = this.props.transform
        }
        return (
            <div className="audio">
                <audio src={this.props.prefix + this.props.src} ref="media"/>
            </div>
        )
    }
}


class Position extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showContext: this.props.player.state.showContext
        }
        this.seekScale = 25
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleContext = this.handleContext.bind(this);
    }

    handleClick(event) {
        if (this.props.player.state.paused) {
            this.props.player.play()
        } else {
            this.props.player.pause()
        }
        event.preventDefault();
    }

    handleChange(event) {
        var position = parseFloat(event.target.value)
        if (event.target.id !== "current") {
            position /= this.seekScale
        }
        this.props.player.setCurrentTime(position)
        event.preventDefault();
    }

    handleContext(event) {
        this.setState({
            showContext: !this.state.showContext
        })
        this.props.player.setState({
            showContext: !this.props.player.state.showContext
        })
    }

    handleSubmit(event) {
        event.preventDefault();
    }
    render() {
        var currentTime = this.props.player.state.currentTime.toFixed(3)

        return (
            <div>
            <div className="position">
            <input type="button" value={this.props.player.state.paused ? 'Play ' : 'Pause'} onClick={this.handleClick} />
            <input id="current" type="text" value={currentTime} onChange={this.handleChange} />
            <input type="range" min="0" max={this.props.player.state.duration * this.seekScale} value={currentTime * this.seekScale}  onChange={this.handleChange} />
            </div>
            <div className="options">
                <label><input type="checkbox" checked={this.state.showContext}
                            onChange={this.handleContext}
                />Show Context</label>
            </div>
            </div>
        )
    }
}

class Player extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            //prefix: this.props.yaml.split('/').slice(0, -1).join('/') + '/',
            prefix: '/media/',
            tracks: [],
            yamlData: '',
            seekPoints: [],
            currentTime: this.props.startTime,
            duration: 0,
            paused: !this.props.autoplay, // fixme: workaround to not display pause overlay on start...
            muted: false,
            speak: false,
            volume: 1,
            showContext: !this.props.timeline
        }
        this.interval = null
        this.hideMouse = null
        this.start = null
        this.initialPosition = this.props.startTime
        this.ticks = []
        this.cachedImages = []
        this.loadYaml = this.loadYaml.bind(this);
        this.tick = this.tick.bind(this);
        this.registerTick = this.registerTick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.seeked = false
        this.seeking = false
        this.loadURL()
        window.addEventListener('resize', this.onResize, false);
        window.addEventListener('keydown', this.onKeyDown, false);
        window.addEventListener('mousemove', this.onMouseMove, false);
        window.addEventListener('touchstart', this.onMouseMove, false);
        window.player = this
        if (this.props.broadcast) {
            reportErrors()
            this.connectWS()
            var last = 0
            this.registerTick((currentTime) => {
                if (currentTime !== undefined  && Math.abs(currentTime-last) >= 1) {
                    last = currentTime
                    window.player.postMessage('tick', currentTime)
                }
            })
        }
    }

    loadURL() {
        fetch(this.props.yaml + '?' + +new Date()).then(response => {
            if (response.status !== 200) {
                console.log('failed to load', response)
            } else {
                response.text().then(this.loadYaml)
            }
        })
    }

    reloadURL() {
        var this_ = this
        fetch(this.props.yaml + '?' + +new Date()).then(response => {
            if (response.status !== 200) {
                console.log('failed to load', response)
            } else {
                response.text().then((text) => {
                    if (text !== this_.state.yamlData) {
                        this_.loadYaml(text)
                        this_.postMessage('reload-yaml', {})
                    }
                })
            }
        })
    }

    cacheImages() {
        this.cachedImages = []
        this.state.tracks.forEach((track) => {
            track.clips.forEach((clip) => {
                if (clip.type === 'image') {
                    var img = document.createElement('img')
                    img.src = clip.src
                    this.cachedImages.push(img)
                }
            })
        })
    }

    loadYaml(text) {
        var this_ = this
        var playlist = loadPlaylist(text)
        this.setState({
            yamlData: text,
            tracks: playlist.tracks,
            duration: playlist.duration,
            seekPoints: playlist.seekPoints
        }, () => {
            if (this_.props.autoplay) {
                setTimeout(() => {
                    this_.play()
                    //this_.cacheImages()
                }, 1000)
            } else if (!this_.state.paused) {
                this_.play()
            } else {
                this_.tick()
            }
        })
    }

    connectWS() {
        var this_ = this;
        var url = document.location.origin.replace('https://', 'wss://').replace('http://', 'ws://') + '/ws/'
        this.ws = new WebSocket(url)
        this.ws.onmessage = (event) => {
            var data 
            try {
                data = JSON.parse(event.data)
            } catch {
                data = null
            }
            if (data) {
                var action = data[0]
                data = data[1]
                //console.log('!!', action, data)
                if (action === 'reload') {
                    document.location.reload()
                }
            }

        }
        this.ws.onopen = (event) => {
            console.log('ws connected')
            this_.postMessage('open', this_.props.project)
        }
        this.ws.onerror = (event) => { this_.ws.close() }
        this.ws.onclose = (event) => { setTimeout(() => { this_.connectWS() }, 500) }
    }

    postMessage(action, data) {
        if (this.ws) {
            try {
                this.ws.send(JSON.stringify([action, data]))
            } catch {}
        }
    }

    tick(currentTime) {
        var now = new Date()
        if (this.state.paused) {
            this.ticks.forEach(tick => { tick(currentTime) })
        } else {
            if (currentTime === undefined && this.props.dash && this.dash) {
                currentTime = this.dash.getCurrentTime()
            }
            if (currentTime === undefined) {
                currentTime = this.initialPosition + (now - this.start) / 1000
            }
            if (currentTime > this.state.duration) {
                currentTime = this.state.duration
                //this.pause()
                //loop
                this.setCurrentTime(0)
                if (this.props.autoplay) {
                    this.reloadURL()
                }
            }
            this.setState({
                currentTime: currentTime
            })
            this.ticks.forEach(tick => { tick(currentTime) })
        }
        if (this.props.project && currentTime !== undefined) {
            window.localStorage[this.props.project + '_currentTime'] = currentTime
        }
    }

    registerTick(tick) {
        this.ticks.push(tick)
    }

    play() {
        var this_ = this
        if (this.interval) {
            clearInterval(this.interval)
        }
        this.interval = setInterval(this.tick, 1000/25)
        this.start = new Date()
        this.setState({paused: false})
        this.hideOverlay()
        if (!this.props.timeline) {
            this_.hideMouse = setTimeout(() => {
                document.body.classList.add('hide-mouse')
            }, 1000);
        }
    }

    pause() {
        if (this.interval) {
            clearInterval(this.interval)
        }
        this.setState({paused: true}, () => {
            this.initialPosition = this.state.currentTime
            this.start = null
        })
    }

    isSeeking() {
        if (this.seeking) {
            return true
        }
        if (this.dash) {
            return !this.dash.refs.media || this.dash.refs.media.seeking || this.dash.refs.media.readyState !== 4
        }
        return false
    }

    setCurrentTime(currentTime) {
        var play = true
        this.seeking = true
        if (this.state.paused) {
            play = false
        } else {
            this.pause()
        }
        this.initialPosition = currentTime
        this.start = null
        this.setState({
            currentTime: currentTime
        }, () => {
            this.seeked = true
            this.seeking = false
            if (this.dash && this.dash.refs.media) {
                console.log('seek in player', currentTime)
                this.dash.refs.media.currentTime = currentTime
                if (this.dashAudio && this.dashAudio.refs.media) {
                    this.dashAudio.refs.media.currentTime = currentTime
                }
            }
            this.tick(currentTime)
            this.seeked = false
            if (play) {
                this.play()
            }
            if (this.props.timeline) {
                this.timeline.scrollToPosition(currentTime)
            }
        })
    }

    handleClick(event) {
        /*
        if (this.state.paused) {
            this.play()
        } else {
          this.pause()
        }
        */
        //event.preventDefault();
    }

    onResize(event) {
        this.setState({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        })
    }
    onKeyDown(event) {
        if (['input', 'textarea', 'button'].indexOf(event.target.type) > -1) {
            return
        }
        var track, clip
        //console.log(event.target.type, event.key, event.keyCode)
        if (!this.props.timeline || event.target.type === undefined) {

            const PREVIOUS_CUT = 188 // < previous
            const NEXT_CUT = 190 // > next

            const PREVIOUS_SEEKPOINT = 219 // [ previous
            const NEXT_SEEKPOINT = 221 // ] next
            const PAUSE = 32 // SPACE
            const PLAY = 80 // p
            const START = 48 // 0

            const NEXT_FRAME = 39 // arrow right
            const PREVIOUS_FRAME = 37 // arrow left

            const NEXT_MINUTE = 40 // arrow down
            const PREVIOUS_MINUTE = 38 // arrow up

            //const MUTE = 77  // m mute
            const MUTE = null  // m mute
            const TOGGLE_SPEAK = 81 // q
            const VOLUME_UP = 65 // a volume up
            const VOLUME_DOWN = 90  // z volume down
            const VOLUME_STEP = 1/10

            if (event.keyCode === PAUSE) {
                if (this.state.paused) {
                    this.play()
                } else {
                    this.pause()
                }
                event.preventDefault();
            } else if (event.keyCode === PLAY) {
                if (this.state.paused) {
                    this.play()
                }
            } else if (event.keyCode === PREVIOUS_SEEKPOINT) {
                console.log(this.state.seekPoints)
                var previous = this.state.seekPoints.filter(p => { return p < this.state.currentTime })
                if (previous.length) {
                    previous = previous[previous.length-1]
                } else {
                    // loop
                    previous = this.state.seekPoints[this.state.seekPoints.length-1]
                }
                this.setCurrentTime(previous)
                event.preventDefault();
            } else if (event.keyCode === NEXT_SEEKPOINT) {
                var next = this.state.seekPoints.filter(p => { return p > this.state.currentTime })
                if (next.length) {
                    next = next[0]
                } else {
                    // loop
                    next = 0
                }
                this.setCurrentTime(next)
                event.preventDefault();
            } else if (event.keyCode === PREVIOUS_CUT) {
                track = this.state.tracks.filter(t => {
                    return t.stacked
                })[0]
                clip = track.clips.filter(c => {
                    return c._position < this.state.currentTime
                })
                if (clip.length) {
                    clip = clip[clip.length - 1]
                } else {
                    clip = track.clips[track.clips.length - 1]
                }
                //console.log('previous', clip)
                clip && this.setCurrentTime(clip._position)
                event.preventDefault();
            } else if (event.keyCode === NEXT_CUT) {
                track = this.state.tracks.filter(t => {
                    return t.stacked
                })[0]
                if (track) {
                    clip = track.clips.filter(c => {
                        return c._position > this.state.currentTime
                    })
                    if (clip.length) {
                        clip = clip[0]
                    } else {
                        clip = track.clips[0]
                    }
                    //console.log('next', clip)
                    clip && this.setCurrentTime(clip._position)
                }
                event.preventDefault();
            } else if (event.keyCode === MUTE) {
                this.setState({
                    muted: !this.state.muted
                })
                event.preventDefault();
            } else if (event.keyCode === VOLUME_UP) {
                if (this.state.volume < 1) {
                    this.setState({
                        volume: Math.min(this.state.volume + VOLUME_STEP, 1)
                    })
                }
                event.preventDefault();
            } else if (event.keyCode === VOLUME_DOWN) {
                if (this.state.volume > 0) {
                    this.setState({
                        volume: Math.max(this.state.volume - VOLUME_STEP, 0)
                    })
                }
                event.preventDefault();
            } else if (event.keyCode === NEXT_FRAME) {
                this.setCurrentTime(this.state.currentTime + 1/this.props.fps)
                event.preventDefault();
            } else if (event.keyCode === PREVIOUS_FRAME) {
                this.setCurrentTime(this.state.currentTime - 1/this.props.fps)
                event.preventDefault();
            } else if (event.keyCode === NEXT_MINUTE) {
                this.setCurrentTime(this.state.currentTime + 60)
                event.preventDefault();
            } else if (event.keyCode === PREVIOUS_MINUTE) {
                this.setCurrentTime(this.state.currentTime - 60)
                event.preventDefault();
            } else if (event.keyCode === START && event.shiftKey) {
                this.setCurrentTime(0)
                event.preventDefault();
            } else if (event.keyCode === TOGGLE_SPEAK) {
                this.setState({
                    speak: !this.state.speak
                })
                event.preventDefault();
            } else {
                //console.log(event.keyCode, event)
            }
        }
    }

    hideOverlay() {
        if (this.hideMouse) {
            clearTimeout(this.hideMouse)
        }
        this.lastHide = +new Date()
        this.setState({
            showOverlay: false,
        })
    }

    onMouseMove(event) {
        var this_ = this
        if (this.lastHide && ((+new Date()) - this.lastHide) < 1000) {
            return
        }
        if (this_.hideMouse) {
            clearTimeout(this_.hideMouse)
            this_.setState({showOverlay: true})
            this_.hideMouse = setTimeout(() => {
                document.body.classList.add('hide-mouse')
                this_.setState({showOverlay: false})
            }, 3000);
        }
        if (document.body.classList.contains('hide-mouse')) {
            document.body.classList.remove('hide-mouse')
            this_.setState({showOverlay: true})
            clearTimeout(this_.hideMouse)
            this_.hideMouse = setTimeout(() => {
                document.body.classList.add('hide-mouse')
                this_.setState({showOverlay: false})
            }, 2000);
        }
    }

    getMPD(type) {
        var mpd = this.props.yaml.replace('.yaml', type ? ('.' + type + '.mpd') : '.mpd')
        return mpd
    }

    render() {
        var this_ = this;
        var videoTracks = this.state.tracks.filter(track => { return track.type === 'video' })
        var audioTracks = this.state.tracks.filter(track => { return track.type === 'audio' })
        var titleTracks = this.state.tracks.filter(track => { return track.type === 'title' })
        var textTracks = this.state.tracks.filter(track => { return track.type === 'text' })
        var style = {}
        var className = 'player'

        var videoWidth = 1920
        var videoHeight = 1080
        var playerWidth = this.state.windowWidth
        var playerHeight = this.state.windowHeight
        if (this.props.timeline) {
            playerWidth = videoWidth / 2
            playerHeight = videoHeight / 2
        }
        var ratio = playerWidth / playerHeight
        var frameRatio = videoWidth / videoHeight
        var heightRatio = playerHeight / videoHeight
        var widthRatio = playerWidth / videoWidth
        var scale =  Math.min(heightRatio, widthRatio)
        var frameWidth = videoWidth * scale
        var frameHeight = videoHeight * scale
        style.transform = "scale(" + scale + ")"
        this.scale = scale
        if (frameRatio >= ratio) {
            style.top = 'calc((' + playerHeight + 'px - ' + frameHeight + 'px) / 2)'
        } else {
            style.left = 'calc(('+ playerWidth + 'px - ' + frameWidth + 'px) / 2)'
        }
        return (
            <div className="frame">
                <div className={className} onClick={this.handleClick} style={style}>
                    {videoTracks.reverse().map(function(track) {
                        return <VideoTrack player={this_} track={track.idx} key={track.idx} />
                    })}
                    {titleTracks.reverse().map(function(track) {
                        return <TitleTrack player={this_} track={track.idx} key={track.idx} />
                    })}
                    {textTracks.reverse().map(function(track) {
                        return <TextTrack player={this_} track={track.idx} key={track.idx} />
                    })}
                    {audioTracks.map(function(track) {
                        return <AudioTrack player={this_} track={track.idx} key={track.idx} />
                    })}
                    {(!this.props.timeline || this.state.showContext) &&
                    <PlayerOverlay player={this_} />
                    }
                </div>
                {this.props.timeline &&
                    <div className="controls">
                        <Position player={this_} />
                    </div>
                }
                {this.props.timeline &&
                    <Timeline player={this_} yaml={this_.props.yaml} />
                }
            </div>
        )
    }
}

Player.defaultProps = {
  yaml: '/cache/timeline.yaml',
  startTime: 0,
  fps: 25,
  broadcast: false,
  dash: false
}

export { Player, Track, Text };
