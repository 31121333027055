import YAML from 'yaml';

function clipDuration(clip) {
    var duration = clip.duration
    if (clip.playbackRate) {
        duration /= parseFloat(clip.playbackRate)
    }
    if (clip.freeze) {
        duration += Object.values(clip.freeze).reduce((a, b) => a + b, 0)
    }
    return duration
}

function loadPlaylist(text) {
    var playlist = {
        tracks: YAML.parse(text)
    }
    var positions = []
    var seekPoints = [0]
    if (playlist.tracks) {
        playlist.tracks.forEach((track, idx) => {
            var position = 0
            track.idx = idx
            track.clips.forEach((clip, clip_idx) => {
                if (track.stacked && 'position' in clip) {
                    delete clip.position
                }
                clip._position = !clip.position ? position : clip.position
                if (track.stacked) {
                    clip._dashPosition = clip._position
                }
                if (clip.seekPoint) {
                    seekPoints.push(clip._position)
                }
                if (track.type === 'overlay' && clip.duration) {
                    var next = track.clips[clip_idx + 1]
                    var n = 2
                    while (next && !next.duration) {
                        next = track.clips[clip_idx + n]
                        n++
                    }
                    if (next) {
                        var max = next.position
                        if (position + clip.duration > max) {
                            var duration = next.position - position
                            console.log('adjust overlay duration', clip_idx, clip.duration - duration)
                            clip.duration = next.position - position
                        }
                    }
                }
                position = clip._position + clipDuration(clip)
            })
            positions.push(position)
        })
        playlist.duration = Math.max.apply(null, positions)
    } else {
        playlist.tracks = []
        playlist.duration = 0
    }
    playlist.seekPoints = seekPoints
    recomputeOverlapping(playlist.tracks)
    return playlist

}

function recomputeOverlapping(tracks) {
    tracks.forEach(track => {
        track.clips.forEach((clip, clip_idx) => {
            if (clip_idx
                && (clip.position < (
                    track.clips[clip_idx-1].position + clipDuration(track.clips[clip_idx-1])
                ))
            ) {
                clip._overlapping = true
            } else {
                clip._overlapping = false
            }
        })
    })
}

export { loadPlaylist, clipDuration, recomputeOverlapping };
