import React, { Component, } from 'react';
import { clipDuration } from './playlist';
import { isMobile, formatSeconds } from './utils';

import './overlay.scss';

class ChapterBar extends Component {
    constructor(props) {
        super(props);
        this.handleSeek = this.handleSeek.bind(this);
    }

    handleSeek(event) {
        var position = parseFloat(event.target.parentElement.dataset.position || 0)
        this.props.player.setCurrentTime(position)
        this.props.player.play()
    }
    render() {
        var points = []
        this.props.player.state.tracks.forEach(track => {
            track.clips.forEach(clip => {
                if (clip.seekPoint) {
                    var frame = this.props.player.state.prefix + clip.src + '.jpg'
                    points.push({
                        position: clip._position,
                        frame: frame,
                        title: clip.chapterTitle || clip._position
                    })
                }
            })
        })

        return (
            <div className="nav">
                <div className="nav-item" onClick={() => { document.location.href = '' }}>
                    <span className="nav-title">Index</span>
                    <img alt="Index" src="/svg/format_list_numbered_18px.svg" className="list" />
                </div>
                {points.map(point => {
                    var key = [point.position, point.frame]
                    return (
                        <div className="nav-item" onClick={this.handleSeek} data-position={point.position} key={key}>
                            <span className="nav-title">{point.title}</span>
                            <img alt={point.title} src={point.frame} />
                        </div>
                    )
                })}
                <div className="nav-item">
                <FullScreen />
                </div>
            </div>
        )
    }
}
class Position extends Component {
    render() {
        var position = ''
        if (this.props.player.state.targetTime) {
            position = this.props.player.state.targetTime
        } else if (this.props.player.state.currentTime) {
            position = formatSeconds(this.props.player.state.currentTime)
        }
        return (
            <div className="position">
                <div>{position}</div>
            </div>
        )
    }
}

class PlayPause extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

    toggle(event) {
        if (this.props.player.state.paused) {
            this.props.player.play()
        } else {
            this.props.player.pause()
        }
    }

    render() {
        return (
            <div className="playpause" onClick={this.toggle} title={this.props.player.state.paused ? 'Play' : 'Pause'}>
                {this.props.player.state.paused ?
                    <img alt="Play" src="/svg/play.svg"/>
                :   <img alt="Pause" src="/svg/pause.svg"/>
                }
            </div>
        )
    }
}

class SeekBar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.state.showTarget = false
        this.state.targetOffset= 0
        this.handleSeek = this.handleSeek.bind(this);
        this.clickChapter = this.clickChapter.bind(this);
        this.mouseEnter = this.mouseEnter.bind(this);
        this.mouseLeave = this.mouseLeave.bind(this);
        this.mouseMove = this.mouseMove.bind(this);
        var chapters = []
        this.props.player.state.tracks.forEach(track => {
            track.clips.forEach(clip => {
                if (clip.seekPoint) {
                    var frame = this.props.player.state.prefix + clip.src + '.jpg'
                    chapters.push({
                        position: clip._position,
                        frame: frame,
                        title: clip.chapterTitle || clip._position
                    })
                }
            })
        })
        this.state.chapters = chapters
    }

    getPosition(event) {
        var pos = event.clientX
        pos = pos/ this.props.player.scale - this.refs.seekbar.offsetLeft
        var percent = pos / this.getWidth()
        var position = percent * this.props.player.state.duration
        return position
    }

    getChapter(position) {
        var current = ''
        this.state.chapters.forEach(chapter => {
            if (chapter.position <= position) {
                current = chapter
            }
        })
        return current
    }

    handleSeek(event) {
        this.props.player.setCurrentTime(this.getPosition(event))
        this.props.player.play()
    }

    clickChapter(event) {
        this.props.player.setCurrentTime(this.state.chapterPosition)
        this.props.player.play()
        event.stopPropagation();
        event.preventDefault();
    }

    mouseEnter(event) {
        this.setState({showTarget: true, targetTime: ''})
    }
    mouseLeave(event) {
        this.setState({showTarget: false})
        this.props.player.setState({targetTime: ''})
    }
    mouseMove(event) {
        var position = this.getPosition(event)
        if (position < 0) {
            this.setState({showTarget: false, targetTime: ''})
            this.props.player.setState({
                targetTime: ''
            })
        } else {
            var targetTime = formatSeconds(position)
            var chapter = this.getChapter(position)
            var targetOffset = event.clientX - 82
            targetOffset = targetOffset / this.props.player.scale
            targetOffset = Math.max(targetOffset, this.refs.seekbar.offsetLeft)
            this.setState({
                showTarget: true,
                targetOffset: targetOffset,
                targetTime: targetTime,
                targetPosition: position,
                chapterTitle: chapter ? chapter.title : '',
                chapterFrame: chapter ? chapter.frame : '',
                chapterPosition: chapter ? chapter.position : 0
            })
            this.props.player.setState({
                targetTime: targetTime
            })
        }
    }
    getWidth() {
        return this.refs.seekbar ? this.refs.seekbar.offsetWidth : 0
    }
    render() {
        var style = {}
        if (this.props.player.state.currentTime && this.props.player.state.duration) {
            style.width = (this.props.player.state.currentTime / this.props.player.state.duration) * this.getWidth()
        }
        var targetStyle = {
            //left: this.state.targetOffset + 'px',
            //display: this.state.showTarget ? 'flex' : 'none'
            display: 'none'
        }
        var chapterStyle = {
            left: Math.min(this.state.targetOffset, this.getWidth())  + 'px',
            display: this.state.showTarget ? 'block' : 'none'
        }
        if (this.refs.seekbar) {
            chapterStyle.top = (this.refs.seekbar.offsetHeight - 200) + 'px'
        }
        return (
            <div className="seekbar"  onClick={this.handleSeek} ref="seekbar"
                onMouseEnter={this.mouseEnter}
                onMouseLeave={this.mouseLeave}
                onMouseMove={this.mouseMove}
            >
                <div className="chapter" style={chapterStyle} onClick={this.clickChapter}>
                    <img alt={this.state.chapterTitle} src={this.state.chapterFrame} />
                    <div>{this.state.chapterTitle}</div>
                </div>
                <div className="target" style={targetStyle}><div>{this.state.targetTime}</div></div>
                <div className="played" style={style}></div>
            </div>
        )
    }
}
class Navbar extends Component {
    render() {
        return (
            <div className="seek">
                <div className="index" onClick={() => { document.location.href = '' }}>
                    <span className="nav-title">Index</span>
                    <img alt="Index" src="/svg/format_list_numbered_18px.svg" className="list" />
                </div>
                <PlayPause player={this.props.player} />
                <Position player={this.props.player} />
                <SeekBar player={this.props.player} />
                <FullScreen />
            </div>
        )
    }
}



class Context extends Component {
    constructor(props) {
        super(props);
        this.handlePlay = this.handlePlay.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    handlePlay() {
        this.props.player.play()
    }

    currentClip() {
        var overlay = this.props.player.state.tracks.filter(t => { return t.type === 'overlay' })[0]
        var currentTime = this.props.player.state.currentTime
        var currentClip = null
        overlay && overlay.clips.some(clip => {
            var out = clip._position + clipDuration(clip)
            if (out <= clip._position) {
                return false
            }
            if (currentTime >= clip._position && currentTime < out) {
                currentClip = clip
                return true
            }
            return false
        })
        return currentClip
    }

    getRefs(string) {
        if (!this.props.player.state.showContext) {
            return null
        }
        if (isMobile()) {
            return null
        }
        var currentClip = this.currentClip()
        if (currentClip) {
            var refs = ''
            if (currentClip.context) {
                refs = currentClip.context
            } else {
                refs = currentClip.text.split('\n\n').map(ref => {
                    if (ref.slice(0, 4) === 'http') {
                        ref = ref.split('\n')
                        var url = ref.shift()
                        ref = ref.join('\n')
                        return `<div class="ref"><div class="details"><a href="${url}" target="_blank">${url}</a>${ref}</div></div>`
                    } else {
                        return `<div class="ref">${ref}</div>`
                    }
                }).join('\n\n')
                refs = refs.replace('\n', ' ')

            }
            return {__html: refs}

        } else {
            return null
        }
    }

    cycle(direction) {
        var currentClip = this.currentClip()
        var overlay = this.props.player.state.tracks.filter(t => { return t.type === 'overlay' })[0]
        var clips = overlay.clips.filter(c => { return c.duration > 0 })
        var current = clips.indexOf(currentClip)
        var next = current + direction
        if (next >= clips.length) {
            next = 0
        }
        if (next < 0) {
            next = overlay.length -1
        }
        this.props.player.setCurrentTime(clips[next].position)
    }

    next(event) {
        this.cycle(1)
    }

    previous(event) {
        this.cycle(-1)
    }

    render() {
        //fixme: only render refs if defined
        var refs = this.getRefs()
        return refs ? (
            <div className="context with-refs">
                <div className="refs" dangerouslySetInnerHTML={refs} />
                <div className="ref-control"><div className="previous" title="Previous" onClick={this.previous}><img alt="Previous" src="svg/previous.svg" /></div><div className="next" title="Next" onClick={this.next}><img alt="Next" src="svg/next.svg" /></div></div>
                <Navbar player={this.props.player} />
            </div>
        ) : (
            <div className="context play">
                <div className="control" onClick={this.handlePlay} >
                    <img alt="Play" src="/svg/play.svg"/>
                </div>
                <Navbar player={this.props.player} />
            </div>
        )
    }
}

class FullScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: document.fullscreen,
        }
        this.handleFullscreen = this.handleFullscreen.bind(this);
    }
    handleFullscreen() {
        this.setState({
            fullscreen: !this.state.fullscreen
        }, () => {
            if (this.state.fullscreen) {
                document.body.requestFullscreen()
            } else {
                document.exitFullscreen()
            }
        })
    }

    render() {
        var fullscreen_svg = this.state.fullscreen ? '/svg/exitFullscreen.svg' : '/svg/enterFullscreen.svg';
        return (
                <div className="fullscreen" onClick={this.handleFullscreen} title="Fullscreen" >
                    <img alt="Fullscreen" src={fullscreen_svg} />
                </div>
        )
    }
}

class Overlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.handlePause = this.handlePause.bind(this);
        this.handlePlay = this.handlePlay.bind(this);
    }

    handlePlay() {
        this.props.player.play()
    }

    handlePause() {
        this.props.player.pause()
    }

    render() {
        var style = {}
        style.opacity = this.props.player.state.showOverlay ? 1 : 0;
        return (
            <div className="context play" style={style}>
                <div className="control" onClick={this.handlePause} >
                    {isMobile()? 
                        <img alt="Pause" src="/svg/pause.svg"/>
                    :
                        <img alt="Pause" src="/svg/pause.svg"/>
                    }
                </div>
                <Navbar player={this.props.player} />
            </div>
        )
    }
}

function Seeking() {
    return (
        <div className="seeking">
            <div className="loading-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

class PlayerOverlay extends Component {
    render() {
        var className = 'overlay'
        var paused = false
        //if (this.props.player.state.paused && !this.props.player.props.timeline) {
        if (this.props.player.state.paused) {
            className += ' paused'
            paused = true
        }
        return (
            <div className={className}>
                {paused ?
                    <Context player={this.props.player} />
                : this.props.player.isSeeking() ?
                    <Seeking player={this.props.player} />
                : <Overlay player={this.props.player} />
                }
            </div>
        )
    }
}

export { PlayerOverlay, Context, Overlay };
