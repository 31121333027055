
function freezeRanges(freezes, duration) {
    var tp = 0, vp = 0, ranges = [];
    console.log(freezes)
    Object.keys(freezes).forEach(freeze => {
        freeze = parseFloat(freeze)
        if (freeze > vp) {
            var d = freeze - vp
            ranges.push(['v', tp, tp + d, vp])
            tp += d
            vp += d
        }
        ranges.push(['f', tp, tp+freezes[freeze]])
        tp += freezes[freeze]
    })
    if (vp < duration) {
        var d = duration - vp
        ranges.push(['v', tp, tp + d, vp])
    }
    return ranges
}


function inFreeze(freeze, duration, currentTime) {
    if (!freeze) {
        return false
    }
    var ranges = freezeRanges(freeze, duration), inFreeze = false
    ranges.some(range => {
        //console.log(range[1], currentTime, range[2])
        if (currentTime >= range[1] && currentTime < range[2]) {
            inFreeze = range[0] === 'f'
            //console.log('current', range, currentTime)
            return true
        }
        return false
    })
    //console.log(currentTime, ranges, inFreeze)
    return inFreeze
}

function freezeTime(freeze, duration, currentTime) {
    if (!freeze) {
        return false
    }
    var ranges = freezeRanges(freeze, duration), inFreeze = false
    ranges.some(range => {
        //console.log(range[1], currentTime, range[2])
        if (currentTime >= range[1] && currentTime < range[2]) {

            if(range[0] === 'f') {
                inFreeze = range[1]
            }
            //console.log('current', range, currentTime)
            return true
        }
        return false
    })
    //console.log(currentTime, ranges, inFreeze)
    return inFreeze
}


function frozenPast(freeze, duration, currentTime) {
    var ranges = freezeRanges(freeze, duration, ), past = 0
    ranges.some(range => {
        if (range[1] < currentTime) {
            if (range[0] === 'f') {
                past += range[2] - range[1]
            }
            return false
        }
        return true
    })
    //console.log('past', currentTime, past)
    return past
}

export { freezeRanges, inFreeze, freezeTime, frozenPast };
