import React, { Component, } from 'react';
import { render } from 'react-dom';

import { Player } from './timeline-player';
import { SecondScreen } from './second-screen';
import { Overview } from './overview';
import { About } from './about';

class App extends Component {
    constructor(props) {
        super(props);
        this.MODES = ['p', 'e', 's', 'f', 'about']

        this.state = {
            isPlayer: this.isPlayer(),
            project: this.getProject(),
            yaml: this.getYaml(),
            startTime: this.getStartTime()
        }
        this.onHashChange = this.onHashChange.bind(this);
        window.addEventListener('hashchange', this.onHashChange, false);
        window.app = this
    }

    isPlayer() {
        return ['p', 'f'].indexOf(this.getMode()) > -1
    }

    getPath() {
        var path = document.location.hash.slice(1)
        if (!path.length) {
            path = document.location.pathname.slice(1)
        }
        return path
    }

    getMode() {
        var project = this.getPath()
        var mode = project.split('/')
        if (this.MODES.indexOf(mode[0]) > -1) {
            mode = mode[0]
        } else {
            mode = 'e'
        }
        return mode
    }

    getProject() {
        var project = this.getPath()
        var mode = project.split('/')
        if (this.MODES.indexOf(mode[0]) > -1) {
            project = mode.slice(1).join('/')
        }
        return project
    }

    getStartTime() {
        var t = parseFloat(localStorage[this.getProject() + '_currentTime'] || 0)
        t = isNaN(t) ? 0 : t
        return t
    }

    getYaml() {
        var project = this.getProject(),
            yaml;
        if (project.length) {
            yaml = '/project/' + project + '/timeline.yaml'
        }
        return yaml
    }
    onHashChange() {
        this.setState({
            isPlayer: this.isPlayer(),
            project: this.getProject(),
            yaml: this.getYaml(),
            hashChange: true
        })
    }
    render() {
        var className = 'editor'
        var broadcast = false
        var autoplay = false
        var about = false
        var mode = this.getMode()
        if (mode === 'p') {
            className = 'player'
            if (this.state.hashChange) {
                autoplay = true
            }
        } else if (mode === 'f') {
            className = 'player'
            mode = 'p'
            broadcast = true
            autoplay = true
        } else if (mode === 's') {
            className = 'second'
        }
        if (mode === 'about') {
            about = true
        }
        return this.state.yaml ? mode === 's' ? (
            <div className={className}>
                <SecondScreen
                    yaml={this.state.yaml}
                    project={this.state.project}
                    startTime={this.state.startTime}
                    key={this.state.yaml} />
            </div>
        ) : (
            <div className={className}>
                <Player timeline={!this.isPlayer()}
                        yaml={this.state.yaml}
                        project={this.state.project}
                        startTime={this.state.startTime}
                        key={this.state.yaml}
                        broadcast={broadcast}
                        autoplay={autoplay}
                />
            </div>
        ) : about ? (
            <About />
        ) : (
            <Overview />
        );
    }
}

render(<App />, document.getElementById('root'));
